import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Logo from "../components/logo"

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "background-desktop-contact.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <Layout sectionname="sdfsf">
          <BackgroundImage
            Tag="section"
            className={className}
            fluid={imageData}
            data-pagename='contact-section'
            backgroundColor={`#040e18`}
          >
            <Navigation sectionname="contact-page" />
            <main className="main-container"></main>
            <footer className="contact-page">
              <div className="main-body-content">
                <div style={{float: 'left', width: '60%'}}>
                  27 Merchant's Quay, Dublin 8, 01 538 0040
                  <br />
                  craftcoffeeroasters@gmail.com
                  <br />
                  OPENING HOURS
                  <br />
                  Monday - Friday 7-7PM
                  <br />
                  Saturday, Sunday and Bank Holidays 8-6pm
                </div>
                <div style={{float: 'right', width: '30%'}}>
                  <Logo />
                </div>
              </div>
            </footer>
          </BackgroundImage>
        </Layout>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: center center;
  background-repeat: repeat-y;
  background-size: cover;
  height: 100vh;
  color: white;
`

export default StyledBackgroundSection
